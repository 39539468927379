import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  MutableRefObject
} from 'react';

import { useTranslation, i18n } from 'next-i18next';
import { useInView } from 'react-intersection-observer';
import cookie from 'cookie';
import dynamic from 'next/dynamic';
import classnames from 'classnames';
import LazyHydrate from 'react-lazy-hydration';

import { isValidVideoURL } from '../../../utils/video';
import { mountLink, mountMarketplacePage } from 'utils/url';
import { categories } from '../../../constants';
import { sendAnalyticsEvent } from '../../../utils/datalayer';
import { showCheckoutAtBottom } from '../../../utils/checkout';
import { ProfileContext } from '../../core/Provider/ProfileProvider';
import { getCommonByLanguage } from '../../../../static/locales/common';
import { buyButtonOnClick } from 'utils/events';
import { extractProjectName } from 'utils/string';
import { scrollSpeed, scrollHeader } from 'utils/scroll';
import { isValidFeature } from 'utils/features';
import DatahubService from 'utils/datahub';
import { EVENT_ACTIONS } from 'utils/datahub.constants';
import { getSessionId } from 'utils/cookies';
import { isEventOnline } from 'utils/product/format';
import { isBlackNovember as isBlackNovemberCampaign } from 'utils/campaign';
import { encodeToken } from 'utils/auth';

import Navigation from 'components/Navigation';
import Video from 'components/@redesign/Video';
import MainTitle from 'components/@redesign/MainTitle';
import Description from 'components/@redesign/Description';
import ProductDetails from 'components/@redesign/ProductDetails';
import Checkout from 'components/@redesign/Checkout';

const PreviewAlert = dynamic(() => import('components/PreviewAlert'));
const WebinarComponent = dynamic(() => import('components/WebinarComponent'));
const Faq = dynamic(() => import('components/@redesign/Faq/faq'));
const FaqMobile = dynamic(() => import('components/@redesign/Faq/faq-mobile'));
const ReviewsVariant = dynamic(
  () => import('components/Reviews/ReviewsContainer')
);
const CalendarModal = dynamic(
  () => import('components/Calendar/CalendarModal')
);
const Author = dynamic(() => import('components/@redesign/Author'));
const RelatedProducts = dynamic(
  () => import('components/@redesign/RelatedProducts/')
);
const AccessCourse = dynamic(() => import('components/@redesign/AccessCourse'));
const LaunchProduct = dynamic(() => import('components/LaunchProduct'));

type ContentProps = {
  profile: Profile;
  headerRef: MutableRefObject<HTMLDivElement>;
  ip: string;
  isMobile?: boolean;
};

export const Content = ({ profile, headerRef, ip, isMobile }: ContentProps) => {
  const { t } = useTranslation();
  const context = useContext(ProfileContext);
  const checkout = context?.checkout;
  const query = context?.query;
  const locale = i18n.language;

  const off = query?.off ?? checkout?.off;
  const {
    belongsToMarketplace,
    clubMembershipLink,
    customerReview,
    isActiveOnPlatform,
    salesEnabled,
    showReviewsOnProductProfile
  } = profile;
  const { sck, src, preview, draft } = query;
  const project = extractProjectName(sck);

  const totalRating =
    customerReview?.rating > 0
      ? parseFloat(customerReview.rating.toFixed(1))
      : 0;
  const dataSheet = profile?.product?.dataSheet;
  const format = profile?.product?.format;
  const productId = profile?.product?.productId;
  const videoLink = profile?.product?.videoLink;

  const { abusiveDisclaimerURL } = getCommonByLanguage(locale);
  let formatTranslated = t(format);
  let checkoutItem: Record<string, any> = {};
  let lastPositionScroll = null;
  if (checkout.data.products) {
    checkoutItem = {
      ...checkout.data.products[0],
      creditCardPaymentMethodList: checkout.data.creditCardPaymentMethodList
    };
  }

  const hasVideoDescription = isValidVideoURL(
    videoLink || dataSheet?.cover?.videoLink
  );

  const environment = `${
    sck && sck === 'HOTMART_SITE' ? 'Marketplace' : 'Product'
  }`;

  const [relatedProducts, viewRelatedProducts] = useInView({
    threshold: 0,
    triggerOnce: true
  });

  const [reviewsCard, viewReviews] = useInView({
    threshold: 0,
    triggerOnce: true
  });

  const [showCheckoutComponent, setShowCheckoutComponent] = useState(false);
  const [checkoutAtBottom, setCheckoutAtBottom] = useState(isMobile || false);
  const [showComponentCalendarModal, setShowComponentCalendarModal] =
    useState(false);
  const [sidebarScrolled, setSidebarScrolled] = useState(false);
  const [showHeaderScroll, setShowHeaderScroll] = useState(false);
  const targetSidebarScrolled = useRef<HTMLDivElement>();
  const coverVideo = useRef<HTMLDivElement>();
  const [showSidebarScrolled, setShowSidebarScrolled] = useState(false);
  const [isMobileDevice, setIsMobileDevice] = useState(isMobile);
  const [relatedProductsActive, setRelatedProductsActive] = useState(true);
  const isBlackNovember = isBlackNovemberCampaign(profile?.product, query);

  useEffect(() => {
    if (checkout.modalOnlineService) {
      setShowComponentCalendarModal(true);
    }
  }, [checkout.modalOnlineService]);

  useEffect(() => {
    if (!showSidebarScrolled) {
      setShowSidebarScrolled(format === categories.COURSES_ONLINE);
    }
  }, [format]);

  useEffect(() => {
    setShowCheckoutComponent(true);
  }, []);

  useEffect(() => {
    const cookies = cookie.parse(document.cookie);
    const cookieParsed = encodeToken(cookies.hmVlcIntegration);

    const dataLayerProduct = {
      event: 'pageview',
      section: project,
      subsection: 'Product Details',
      subsection2: `${profile?.product?.category || ''}`,
      language: `${locale || ''}`,
      productName: `${profile?.product?.name || ''}`,
      logged: cookieParsed ? 'true' : 'false',
      productType: `${profile?.product?.format || ''}`
        .replace('category.', '')
        .replace('.name', ''),
      salesEnabled,
      productId
    };

    sendAnalyticsEvent(dataLayerProduct);
  }, [
    ip,
    locale,
    productId,
    profile?.product?.category,
    profile?.product?.format,
    profile?.product?.name,
    project,
    query?.hotfeature,
    salesEnabled
  ]);

  useEffect(() => {
    const hotFeatureParam = isValidFeature(query?.hotfeature);
    const sessionId = getSessionId(ip);

    if (!profile?.product || !sessionId) return;

    DatahubService.sendEvent({
      system: 'display',
      action: EVENT_ACTIONS.VIEW,
      sessionId,
      productId: profile.product?.productId,
      source: hotFeatureParam
    });
  }, [ip, profile.product, profile.product?.productId, query?.hotfeature]);

  useEffect(() => {
    const handleResize = () => {
      setCheckoutAtBottom(showCheckoutAtBottom());
      if (window && window.innerWidth <= 720) {
        setIsMobileDevice(true);
      } else {
        setIsMobileDevice(false);
      }
    };

    const handleOnScroll = () => {
      if (typeof window !== 'undefined') {
        const pageScrolledHeight = targetSidebarScrolled?.current?.scrollHeight;
        const totalHeight = isMobile ? 0 : 120;
        let heightCurrent =
          pageScrolledHeight > 0 ? pageScrolledHeight - totalHeight : 0;

        if (hasVideoDescription) {
          heightCurrent = coverVideo?.current?.scrollHeight;
        }

        let { headerScroll, prevPositionScroll } = scrollSpeed(
          window.scrollY,
          lastPositionScroll
        );
        lastPositionScroll = prevPositionScroll;

        if (typeof headerScroll !== 'undefined') {
          setShowHeaderScroll(headerScroll);
        }

        setTimeout(() => {
          const isSidebarScrolled = window.scrollY >= heightCurrent;
          setSidebarScrolled(isSidebarScrolled);
        }, 250);
      }
    };

    handleResize();
    window.addEventListener('scroll', handleOnScroll, { passive: true });
    window.addEventListener('resize', handleResize, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleOnScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const header = headerRef?.current;
    if (showSidebarScrolled) {
      scrollHeader(sidebarScrolled, showHeaderScroll, header);
    }
  }, [sidebarScrolled, showHeaderScroll, headerRef, showSidebarScrolled]);

  const marketplaceURL = mountLink(mountMarketplacePage(locale), sck, src);
  const mainContent = useRef(null);

  const showCalendarModal = () => {
    return (
      <CalendarModal
        checkout={checkoutItem}
        product={profile?.product}
        buyButtonOnClick={() => buyButtonOnClick(context, ip)}
        t={t}
        clubMembershipLink={clubMembershipLink}
        showAtBottom={isMobileDevice}
        off={off}
      />
    );
  };

  return (
    <div className="redesigned-content">
      {(preview || draft) && (
        <LazyHydrate whenIdle>
          <PreviewAlert
            published={profile?.product?.published}
            activeOnPlatform={isActiveOnPlatform}
          />
        </LazyHydrate>
      )}
      <div
        className={classnames(
          'content-center redesigned-grid _mt-md-4',
          { minimized: !belongsToMarketplace },
          { 'content-preview': preview || draft },
          { 'content-center--marketplace': belongsToMarketplace }
        )}
        ref={mainContent}
        role="main"
      >
        <div className="column-content">
          <Navigation
            locale={locale}
            category={profile?.product?.hotTopic?.topification}
            subCategory={profile?.product?.hotTopic?.topic}
            homeUrl={marketplaceURL}
            isMobile={isMobileDevice}
            title={profile?.product?.name}
            t={t}
            isRedesigned={true}
          />
          <section
            className={classnames('section _mt-md-4', {
              'with-event-online': isEventOnline(format)
            })}
            id="title"
          >
            <MainTitle isMobile={isMobileDevice} profile={profile} />
          </section>

          {checkoutAtBottom && (
            <LaunchProduct
              t={t}
              isMobileDevice={checkoutAtBottom}
              showFooter={sidebarScrolled}
            />
          )}

          <section
            className="section section-divider _pt-6 _pt-md-7"
            id="description"
            ref={hasVideoDescription ? null : targetSidebarScrolled}
          >
            <Description profile={profile} isMobile={isMobileDevice} />
          </section>

          {hasVideoDescription && (
            <section ref={targetSidebarScrolled}>
              <Video
                isMobile={isMobileDevice}
                coverRef={coverVideo}
                isRedesigned={true}
                hasVideoDescription={hasVideoDescription}
              />
            </section>
          )}

          {isMobileDevice && clubMembershipLink && (
            <section className="section section-divider">
              <AccessCourse
                clubMembershipLink={clubMembershipLink}
                salesEnabled={salesEnabled}
                environment={environment}
                productId={productId}
                isMobile={isMobileDevice}
              />
            </section>
          )}

          <section className="section section-divider" id="tabs">
            <ProductDetails />
          </section>

          <section className="section section-divider" id="about-author">
            {hasVideoDescription && (
              <LazyHydrate whenIdle>
                <Author isMobile={isMobileDevice} />
              </LazyHydrate>
            )}
            {!hasVideoDescription && <Author isMobile={isMobileDevice} />}
          </section>

          {isEventOnline(format) && (
            <LazyHydrate whenIdle>
              <WebinarComponent />
            </LazyHydrate>
          )}

          {showReviewsOnProductProfile &&
            customerReview &&
            customerReview?.totalReviews > 0 && (
              <section className="section section-divider" id="review">
                <LazyHydrate whenIdle>
                  <div ref={reviewsCard}>
                    <ReviewsVariant
                      t={t}
                      totalRating={totalRating}
                      query={query}
                      profile={profile}
                      showReviews={viewReviews}
                      isRedesign
                    />
                  </div>
                </LazyHydrate>
              </section>
            )}

          {profile?.showOthersProducts && relatedProductsActive && (
            <section
              className="section section-divider _border-bottom related-products-container"
              id="related-products"
            >
              <LazyHydrate whenVisible>
                <div ref={relatedProducts}>
                  <RelatedProducts
                    producerReferenceCode={
                      profile?.product?.producerReferenceCode
                    }
                    producerUserId={profile?.product?.hotmarter?.userId}
                    showRelatedProducts={viewRelatedProducts}
                    ip={ip}
                    isMobile={isMobileDevice}
                    setRelatedProductsActive={setRelatedProductsActive}
                  />
                </div>
              </LazyHydrate>
            </section>
          )}

          <section className="section section-divider" id="faq">
            <LazyHydrate whenIdle>
              {isMobileDevice ? (
                <FaqMobile
                  profile={profile}
                  showReviews={showReviewsOnProductProfile}
                  rating={totalRating}
                  environment={environment}
                />
              ) : (
                <Faq
                  profile={profile}
                  showReviews={showReviewsOnProductProfile}
                  rating={totalRating}
                  environment={environment}
                />
              )}
            </LazyHydrate>
          </section>
          {abusiveDisclaimerURL && (
            <section className="disclaimer-container" id="abusive-disclaimer">
              <>
                {t('general.abusive_disclaimer.phrase_1')}{' '}
                <a
                  href={abusiveDisclaimerURL}
                  target="_blank"
                  rel="noreferrer"
                  tabIndex={0}
                  aria-label={`${t('general.abusive_disclaimer.phrase_1')} ${t(
                    'general.abusive_disclaimer.phrase_2'
                  )}`}
                >
                  {`${t('general.abusive_disclaimer.phrase_2')}`}
                </a>
              </>
            </section>
          )}
        </div>
        <div className="column-checkout" id="payment">
          <LazyHydrate whenIdle>
            {showCheckoutComponent && (
              <Checkout
                ip={ip}
                showAtBottom={checkoutAtBottom}
                accessCourse={''}
                isMobile={isMobileDevice}
                rating={totalRating}
                showSidebarScrolled={showSidebarScrolled}
                sidebarScrolled={sidebarScrolled}
                formatTranslated={formatTranslated}
                isBlackNovember={isBlackNovember}
              />
            )}
          </LazyHydrate>

          <div
            className={classnames('calendar-modal', {
              opened: context.checkout.modalOnlineService,
              hide: !context.checkout.modalOnlineService,
              'calendar-modal--bn': context.profile.isBlackNovember
            })}
          >
            <LazyHydrate on="click">
              {showComponentCalendarModal && showCalendarModal()}
            </LazyHydrate>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Content);
