import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';

import DatahubService from 'utils/datahub';
import { loaderStaticImage } from 'utils/image';
import { EVENT_ACTIONS, CLICK_SOURCES } from 'utils/datahub.constants';
import { getSessionId } from 'utils/cookies';
import { useTranslation } from 'react-i18next';
import Profile from '../../../@profile/service';
import Card from '../../Card';
import { cardTypes } from '../../../constants';
import IconsImage from '../../Images/Icons';

import { mockedRelatedProducts } from './index.mock';

const ChevronWhite = loaderStaticImage({ src: 'static/images/icons/arrow-solid-white.svg' });
const Glider = dynamic(() => import('react-glider'));

type RelatedProductsProps = {
  ip: string;
  isMobile: boolean;
  producerReferenceCode: string;
  producerUserId: number;
  showRelatedProducts: boolean;
  setRelatedProductsActive: React.Dispatch<React.SetStateAction<boolean>>;
};

const LoadRelatedProducts = async (producerReferenceCode, producerUserId, setOtherProducts, setRelatedProductsActive) => {
  try {
    const otherProducts = (producerReferenceCode === 'L5070471K') ? mockedRelatedProducts : await Profile.getOtherProducts(
      producerReferenceCode,
      producerUserId
    );
    const hasOtherProductsCollection = otherProducts && Array.isArray(otherProducts) && otherProducts.length > 0;

    if (hasOtherProductsCollection) {
      setOtherProducts(otherProducts?.filter((product) => !!product.slug));
    } else {
      setOtherProducts(null);
      setRelatedProductsActive(false);
    }
  } catch (err) {
    console.error(err);
  }
};

function RelatedProducts({ ip, isMobile, producerReferenceCode, producerUserId, showRelatedProducts, setRelatedProductsActive }: RelatedProductsProps) {
  const { t } = useTranslation();
  const [otherProducts, setOtherProducts] = useState([]);
  const sectionTitle = t('general.related_products');

  useEffect(() => {
    if (producerReferenceCode && showRelatedProducts) {
      LoadRelatedProducts(producerReferenceCode, producerUserId, setOtherProducts, setRelatedProductsActive);
    }
  }, [showRelatedProducts]);

  const slidesToShow = isMobile ? 1.5 : 3.5;
  const totalProducts = otherProducts && Object.keys(otherProducts)?.length;
  const hasButtons = (isMobile && totalProducts > 1) || (!isMobile && totalProducts > 3);

  const ProductsList = ({ products }) => products.map(product => (
    <div key={Math.floor(Math.random() * 898) + 101}>
      <Card
        product={product}
        type={cardTypes.PRODUCT_CARD}
        sectionTitle={sectionTitle}
        isMobile={isMobile}
        className="product-card--redesign"
        onClick={() => DatahubService.sendEvent({
          action: EVENT_ACTIONS.CLICK,
          sessionId: getSessionId(ip),
          productId: product?.productId,
          source: CLICK_SOURCES.PRODUCT_PAGE
        })}
      />
    </div>
  ));

  if (!otherProducts || !totalProducts) return null;

  return (
    <div className="related-products">
      <h2 className="_w-full _mb-5 _text-md-5 _font-weight-bold">{sectionTitle}</h2>

      <Glider
        draggable
        hasArrows={hasButtons}
        hasDots={false}
        slidesToScroll={1}
        slidesToShow={slidesToShow}
        className="gradient-outline"
        iconRight={<IconsImage src={ChevronWhite} width={20} height={20} />}
        iconLeft={<IconsImage src={ChevronWhite} width={20} height={20} />}
      >
        <ProductsList products={otherProducts} />
      </Glider>
    </div>
  );
}

export default React.memo(RelatedProducts);
