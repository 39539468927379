import { useTranslation } from 'next-i18next';
import { loaderStaticImage } from 'utils/image';
import IconsImage from 'components/Images/Icons';
import RatingTotal from 'components/RatingTotal';
import ProductImage from 'components/Images/Product';
import ShareButton from 'components/@redesign/ShareButton';
import BlackNovemberBanner from 'components/Banner/BlackNovember';

const LanguageIcon = loaderStaticImage({
  src: 'static/images/icons/language-mobile.svg'
});

const MainTitleMobile = ({
  product,
  ratingStars,
  customerReview,
  language,
  showReviewsOnProductProfile,
  ownerHotmarter
}) => {
  const { t } = useTranslation();
  const languageName = t(`general.language.${language}`);

  return (
    <>
      <div className="_d-flex">
        <div className="redesigned-main-title-image _mr-4">
          <ProductImage
            avatar={product?.avatar}
            src={product?.avatarFinal}
            productFormat={product?.format}
            productName={product?.name}
            data-testid="content-image"
            priority={true}
            width={90}
            height={90}
            isMobile
            isRedesign={false}
          />
        </div>
        <div className="_flex-1">
          <div className="rating-mobile _d-flex _justify-content-between">
            {showReviewsOnProductProfile && (
              <RatingTotal
                showReviewsOnProductProfile={showReviewsOnProductProfile}
                ratingStars={ratingStars}
                reviewTotalAnswers={customerReview?.totalReviews}
                showIcon={false}
                className="_d-flex _align-items-center redesigned-main-title-icon"
              />
            )}
            <ShareButton hideTextButton />
          </div>
          <h1 className="redesigned-main-title-text redesigned-main-title-truncate _mt-0 _my-2 _font-weight-bold _line-height">{product?.name}</h1>
          <span className="redesigned-main-title-author _text-gray-900">{ownerHotmarter?.name}</span>
        </div>
      </div>
      <BlackNovemberBanner
        product={product}
        isMobile={true}
        t={t}
      />
      <div className="_text-1 _d-flex _flex-column _mt-4 _justify-content-between _p-3 _border _rounded-lg redesigned-main-title-tags">
        <div>
          <span className="_text-gray-600"><>{t('product.format')}: </></span>
          <span className="_text-1">{`${t(product?.format)}`}</span>
        </div>
        <div className="_d-flex _align-items-center _pt-3">
          <span><>{t('data_sheet.language')}: </></span>
          <span className="_pl-3 _d-flex redesigned-main-title-icon">
            <IconsImage src={LanguageIcon} width={16} height={16} />
            <span className="_pl-2 _text-1">{languageName}</span>
          </span>
        </div>
      </div>
    </>
  );
};

export default MainTitleMobile;
