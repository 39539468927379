export const mockedRelatedProducts = [
  {
    productId: 2206043,
    avatar: 'https://hotmart.s3.amazonaws.com/product_pictures/4a74dd4b-a1c6-4f02-8bec-953f67755d80/CRIATIVO01STORIESELITEDASMANICURES.jpg',
    avatarFinal: 'https://static-media.hotmart.com/cuE_TFv2mrjHBug1FLvf-gwncPQ=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/4a74dd4b-a1c6-4f02-8bec-953f67755d80/CRIATIVO01STORIESELITEDASMANICURES.jpg',
    slug: 'tecninas-para-unhas-manicure-e-pedicure',
    name: 'Técninas para Unhas. [Manicure e Pedicure]',
    producerReferenceCode: 'K72689382Q',
    format: 'category.ebooks.name',
    category: 'business_and_careers',
    hotmarterName: 'João Gabriel Pacheco Pereira',
    locale: 'pt'
  },
  {
    productId: 2263151,
    avatar: 'https://hotmart.s3.amazonaws.com/product_pictures/1a8e9ea0-0d85-49a7-9f9c-8beefd07c28a/NovoProjeto.png',
    avatarFinal: 'https://static-media.hotmart.com/W18LLOd9mPqZg9hWSrkOmIMGq3M=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/1a8e9ea0-0d85-49a7-9f9c-8beefd07c28a/NovoProjeto.png',
    slug: 'refinanciar-suas-dividas-sem-pagar-juros-0s638',
    name: 'Refinanciar Suas Dívidas Sem Pagar Juros',
    producerReferenceCode: 'V73715476P',
    format: 'category.ebooks.name',
    category: 'finance',
    hotmarterName: 'João Gabriel Pacheco Pereira',
    locale: 'pt'
  },
  {
    productId: 2281887,
    avatar: 'https://hotmart.s3.amazonaws.com/product_pictures/78a86754-bc39-4aab-9f5f-445639ae645a/sucosdetox.jpg',
    avatarFinal: 'https://static-media.hotmart.com/mf7OjKREl6chxCv8eVYvjkyfSM8=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/78a86754-bc39-4aab-9f5f-445639ae645a/sucosdetox.jpg',
    slug: '27-receitas-detox',
    name: '27 Sucos Detox Ingresos',
    producerReferenceCode: 'S74026797G',
    format: 'category.ebooks.name',
    category: 'health_and_sports',
    hotmarterName: 'João Gabriel Pacheco Pereira',
    locale: 'es'
  },
  {
    productId: 2538120,
    avatar: 'https://hotmart.s3.amazonaws.com/product_pictures/76085c83-c154-40b9-b8c8-940045576683/capa1.png',
    avatarFinal: 'https://static-media.hotmart.com/BbYkxZawX-DH-LAj5wxPii1cWhI=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/76085c83-c154-40b9-b8c8-940045576683/capa1.png',
    slug: 'sopa-emagrecedora-5ytrc',
    name: 'Sopa Emagrecedora',
    producerReferenceCode: 'U77828813R',
    format: 'category.ebooks.name',
    category: 'health_and_sports',
    hotmarterName: 'João Gabriel Pacheco Pereira',
    locale: 'pt'
  },
  {
    productId: 2249423,
    avatar: 'https://hotmart.s3.amazonaws.com/product_pictures/7dcd18af-056d-4822-83d9-e87012d4a785/Score5CartoesCapa.png',
    avatarFinal: 'https://static-media.hotmart.com/-ejK0BBCJ6vFEhR0lW9mw-J73Rc=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/7dcd18af-056d-4822-83d9-e87012d4a785/Score5CartoesCapa.png',
    slug: 'os-5-cartoes-de-credito-mais-faceis-de-aprovar-8vpmf',
    name: 'Os 5 Cartões de Crédito Mais Fáceis de Aprovar',
    producerReferenceCode: 'J73463534E',
    format: 'category.ebooks.name',
    category: 'finance',
    hotmarterName: 'João Gabriel Pacheco Pereira',
    locale: 'pt'
  },
  {
    productId: 2224305,
    avatar: 'https://hotmart.s3.amazonaws.com/product_pictures/81ce5d5d-ac7d-4dee-b59b-c4646abe202e/channels4_profile.jpg',
    avatarFinal: 'https://static-media.hotmart.com/j6VknpZ5QhswsqSs55ac57SzN-Q=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/81ce5d5d-ac7d-4dee-b59b-c4646abe202e/channels4_profile.jpg',
    slug: 'quema-de-3-semanas',
    name: 'Quema de 3 semanas',
    producerReferenceCode: 'R73041723L',
    format: 'category.ebooks.name',
    category: 'health_and_sports',
    hotmarterName: 'João Gabriel Pacheco Pereira',
    locale: 'es'
  }
];
