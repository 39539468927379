import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useRef
} from 'react';

import classnames from 'classnames';
import dynamic from 'next/dynamic';
import { withRouter } from 'next/router';
import { ProfileContext } from 'components/core/Provider/ProfileProvider';
import config from '../../../../env.config';
import {
  campaignSections,
  campaignSideBar,
  categories
} from '../../../constants';
import { isSessionPackage } from 'utils/sessions';
import { getCampaignData, activateBlackNovemberTheme } from 'utils/campaign';
import {
  isCourseOnlineFormat,
  isEventOnline,
  isServiceOnlineFormat
} from 'utils/product/format';
import { getEnvironment, isHighRated } from 'utils/data';
import { mountCheckoutLabel, mountCheckoutLabelKey } from 'utils/checkout';
import { i18n, useTranslation } from 'next-i18next';
import { sendAnalyticsEvent } from 'utils/datalayer';
import { mountSalesArguments } from 'utils/datasheet';
import Image from "next/legacy/image";
import { loaderImage } from 'utils/image';

import LinkButton from 'components/LinkButton';
import { mountAccessLink } from '../../Checkout/Sidebar';
import { renderSalesArguments } from './Sidebar/SalesArguments';
import { renderInfoPrice, renderPrice } from '../Checkout/Price';
import { loaderStaticImage } from 'utils/image';
import { mountFormatProduct } from 'utils/string';
import AuthorInfo from '../Author/AuthorInfo';
import RatingTotal from 'components/RatingTotal';
import IconsImage from 'components/Images/Icons';
import classNames from 'classnames';

const CreditCards = dynamic(() => import('components/CreditCards'));
const AccessCourse = dynamic(() => import('components/@redesign/AccessCourse'));
const CalendarContainer = dynamic(
  () => import('components/Calendar/CalendarContainer')
);
const Calendar = dynamic(() => import('components/Calendar'));
const EventSchedule = dynamic(() => import('components/EventSchedule'));
const SessionPackageInfo = dynamic(
  () => import('components/SessionPackageInfo')
);
const ProductFormat = dynamic(() => import('components/ProductFormat'));
const LaunchProduct = dynamic(() => import('components/LaunchProduct'));

type Props = {
  checkoutData: CheckoutAccess;
  campaignData: CampaignData;
  showSidebarScrolled: boolean;
  sidebarScrolled: boolean;
  isMobile: boolean;
};

export const SideCheckout = (props) => {
  const { t } = useTranslation();
  const context = useContext(ProfileContext);
  const {
    checkoutData,
    campaignData,
    showSidebarScrolled,
    sidebarScrolled,
    isMobile
  }: Props = props;
  const {
    checkoutLink,
    checkoutLabel,
    dataCheckout,
    title,
    isBuyable,
    accessCourse,
    discountValue,
    buyButtonOnClick
  } = checkoutData;
  const { campaign = {} } = campaignData;
  const {
    profile: {
      belongsToMarketplace = false,
      clubMembershipLink = '',
      salesEnabled = false,
      product = {},
      productOwner = {},
      showReviewsOnProductProfile = false,
      customerReview = {}
    } = {}
  } = context;
  const { query = {} } = context;
  const { format } = product;
  const alert = query?.preview || query?.draft;
  const { creditCardPaymentMethodList, customizationResponse } = dataCheckout;
  const campaignDataSections = getCampaignData(campaign, campaignSections);
  const environment = getEnvironment(query?.sck);
  const ownerHotmarter =
    productOwner && productOwner.name ? productOwner : product?.hotmarter;
  const ratingStars =
    customerReview?.rating > 0 ? customerReview.rating.toFixed(1) : 0;
  const isHighRatedProduct = isHighRated(customerReview?.rating?.toFixed(1));
  const isBlackNovember = context.profile?.isBlackNovember;
  const showBannerBlackNovember =
    context.checkout.onlineService &&
    isBlackNovember &&
    isServiceOnlineFormat(format);
  const showConsumptionPossibilities =
    context.abTestFlags?.showConsumptionPossibilities;
  const bannerBlackNovember = loaderStaticImage({
    src: 'static/images/black-november/black-november-banner-servico-online.png'
  });
  let HighRatedIcon = loaderStaticImage({
    src: 'static/images/icons/best_rated_black.svg'
  });

  if (isBlackNovember) {
    HighRatedIcon = loaderStaticImage({
      src: 'static/images/black-november/best_rated.svg'
    });
  }

  const campaignStyle = campaignDataSections
    ? {
        backgroundImage: `url('${
          campaignDataSections?.[campaignSideBar.BACKGROUND]?.urlFinal || ''
        }')`
      }
    : null;

  const salesArguments = mountSalesArguments(
    t,
    context.profile,
    product?.dataSheet,
    showSidebarScrolled,
    showConsumptionPossibilities
  );

  const CheckoutContainerRef = useRef(null);
  const [showSalesArguments, setShowSalesArguments] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (typeof window !== undefined) {
        const scrolledHeight = window.innerHeight + window.scrollY;
        const footerHeight =
          document.getElementById('hm-mktplace-footer').scrollHeight;

        if (scrolledHeight >= document.body.scrollHeight - footerHeight) {
          setShowSalesArguments(false);
        } else {
          setShowSalesArguments(true);
        }
      }
    };
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const buyButtonOnClickHandler = () => {
    if (buyButtonOnClick && typeof buyButtonOnClick === 'function') {
      const hasTestAttribute =
        clubMembershipLink ||
        context?.profile?.productMediaConsumer === 'VIDEO_ALL';
      if (hasTestAttribute) {
        window['optimizely'] = window['optimizely'] || [];
        window['optimizely'].push({
          type: 'event',
          eventName: 'add-to-cart',
          tags: {
            value: dataCheckout?.offer?.paymentInfoResponse?.offerPrice?.value
          }
        });
      }

      buyButtonOnClick();
    }
  };

  const showAbout = context.sidebar.opened;

  const { showAccessCourse, showCheckout } = mountAccessLink(
    accessCourse,
    config,
    isBuyable,
    clubMembershipLink,
    product,
    context
  );

  const onAccessClick = () => {
    const cartStatus = salesEnabled ? 'OpenCart' : 'ClosedCart';
    const device = isMobile ? ':Mobile' : '';
    // GA Event
    sendAnalyticsEvent({
      event: 'interaction',
      event_category: `${environment}:Product`,
      event_action: `Click:AccessCourse:${cartStatus}${device}`,
      event_label: `Product:${product.productId}`
    });
  };

  const mountCheckoutLink = (props) => {
    const { priceTitle, infoPrice = null } = title;
    const checkoutLabelKey = mountCheckoutLabelKey(checkoutLabel, isMobile);
    const checkoutButtonLabel = mountCheckoutLabel(
      checkoutLabelKey,
      isBuyable,
      t,
      'knowMore'
    );

    const checkoutAriaLabel = `${checkoutButtonLabel} ${priceTitle} ${
      infoPrice || ''
    }`;

    switch (format?.trim()) {
      case categories.SERVICE_ONLINE:
        return (
          <CalendarContainer
            checkoutLink={checkoutLink}
            isMobile={isMobile}
            language={product?.language}
            ucode={product?.ucode}
          />
        );
      case categories.EVENT_ONLINE:
        return (
          checkoutLink && (
            <LinkButton
              href={checkoutLink}
              text={checkoutButtonLabel}
              ariaLabel={checkoutAriaLabel}
              onClick={buyButtonOnClickHandler}
              icon={'ticket'}
              prefix={'fal'}
              theme={isBuyable ? 'success' : 'blue'}
              size={!isMobile ? 'lg' : 'md'}
              className="btn-buy btn-buy-sidebar 1"
            >
              {checkoutButtonLabel}
            </LinkButton>
          )
        );
      default:
        return (
          checkoutLink && (
            <LinkButton
              href={checkoutLink}
              text={checkoutButtonLabel}
              ariaLabel={checkoutAriaLabel}
              onClick={buyButtonOnClickHandler}
              ctaIcon={campaignDataSections?.[campaignSideBar.LOGO]?.url}
              size={!isMobile ? 'lg' : 'md'}
              theme={isBuyable ? 'success' : 'blue'}
              className="btn-buy btn-buy-sidebar _mt-3"
            >
              {checkoutButtonLabel}
            </LinkButton>
          )
        );
    }
  };

  const renderCheckoutEventOnline = () => {
    return (
      <Fragment>
        <div className="event__online-box">
          <div className="event__online-title">
            <h6 className="event-label">{`${t('online_event.ticket')}`}</h6>
          </div>

          {title &&
            title.priceTitle &&
            renderPrice(title, discountValue, context, campaignDataSections)}

          {renderInfoPrice(title, context, false)}

          {customizationResponse && creditCardPaymentMethodList && (
            <CreditCards
              noSlider
              customizationResponse={customizationResponse}
              creditCardPaymentMethodList={creditCardPaymentMethodList}
              schedule={context.checkout.onlineService}
            />
          )}

          {mountCheckoutLink({ ...props })}
        </div>
      </Fragment>
    );
  };

  const renderCalendar = () => {
    if (
      format === categories.SERVICE_ONLINE &&
      context.checkout.onlineService
    ) {
      return (
        <Calendar
          startDate={new Date()}
          highlightDays={context.online_service.highlightDays}
          availableHours={context.online_service.availableHours}
          locale={i18n.language}
          buyButtonOnClick={buyButtonOnClick}
          checkout={context.checkout}
          profile={context.profile}
        />
      );
    }
    return null;
  };

  const renderEventOnline = () => {
    if (isEventOnline(format)) {
      return (
        <ProductFormat
          tag={t('product.category')}
          complementary={t(`category.${product?.category}.name`)}
          schedule={context.checkout.onlineService}
          className={'category'}
        />
      );
    }
    return null;
  };

  const renderCheckoutCommon = () => {
    return (
      <div className="checkout-container__box">
        {isSessionPackage(context) && !showBannerBlackNovember && (
          <SessionPackageInfo t={t} info={context.online_service.sessionInfo} />
        )}
        <div
          className={classnames('checkout-container__buy', {
            'checkout-container__buy--closed':
              context?.checkout?.data?.checkoutClosed
          })}
        >
          {showBannerBlackNovember && (
            <Image
              src={bannerBlackNovember}
              alt=""
              className="black-november-banner"
              width={400}
              height={70}
              loader={loaderImage}
            />
          )}
          {title &&
            title.priceTitle &&
            !context.checkout.onlineService &&
            renderPrice(title, discountValue, context, campaignDataSections)}

          {renderInfoPrice(title, context, false, false)}

          {mountCheckoutLink({ ...props })}

          <div className="_d-flex _justify-content-between redesigned-main-title-tags _align-items-center">
            {showReviewsOnProductProfile && (
              <RatingTotal
                showReviewsOnProductProfile={showReviewsOnProductProfile}
                ratingStars={ratingStars}
                reviewTotalAnswers={customerReview?.totalReviews}
                showIcon={false}
                className="_d-flex _align-items-center redesigned-main-title-icon"
              />
            )}
            {showReviewsOnProductProfile && isHighRatedProduct && (
              <span className="_d-flex _align-items-center redesigned-main-title-icon _pl-5">
                <IconsImage src={HighRatedIcon} width={20} height={20} />
                <span className="_pl-2">
                  {`${t('sales_arguments.best_reviewed')}`}
                </span>
              </span>
            )}
          </div>
        </div>

        <div
          className={classNames('checkout-container__sales', {
            '_mt-5': showSalesArguments
          })}
        >
          {isBuyable &&
            !context.checkout.onlineService &&
            renderSalesArguments(
              showSidebarScrolled,
              showSalesArguments,
              salesArguments,
              isBlackNovember,
              t
            )}
        </div>

        {renderEventOnline()}
      </div>
    );
  };

  const renderMainCheckout = () => {
    switch (format.trim()) {
      case categories.EVENT_ONLINE:
        return renderCheckoutEventOnline();
      default:
        return renderCheckoutCommon();
    }
  };

  return (
    <>
      <div
        className={classnames('checkout-common _pt-9 _pt-md-11', {
          'checkout-wrapper--marketplace-redesign':
            environment === 'Marketplace',
          'checkout-wrapper--marketplace': belongsToMarketplace,
          'checkout-wrapper--scrolled': sidebarScrolled && showSidebarScrolled,
          'checkout-wrapper--show-sidebar-scrolled': showSidebarScrolled,
          'checkout-alert': alert
        })}
        style={campaignStyle}
      >
        <Fragment>
          <div
            className={classnames(
              'checkOut',
              'side-checkout',
              'show-checkout',
              'box',
              {
                'show-about': showAbout,
                'calendar-opened': isServiceOnlineFormat(format)
              }
            )}
            data-testid="checkout-side"
          >
            <div
              ref={CheckoutContainerRef}
              className={classnames('checkout-container', {
                'online-event-container': isEventOnline(format),
                'checkout-container--bn': isBlackNovember,
                'servico-online-container':  isServiceOnlineFormat(format),
                'checkout-container--courses': isCourseOnlineFormat(format) || activateBlackNovemberTheme(isBlackNovember, format),
                'checkout-container--courses--closed':
                  !context?.profile?.salesEnabled,
                'checkout-container--sticky':
                  sidebarScrolled && showSidebarScrolled
              })}
            >
              <div
                className={classnames(`title`, {
                  schedule: context.checkout.onlineService,
                  'online-event': isEventOnline(format),
                  'title--loading': !title?.priceTitle
                })}
              >
                {!showAccessCourse && isEventOnline(format) && (
                  <EventSchedule
                    dataSheet={product?.dataSheet}
                    position="checkout"
                  />
                )}
              </div>
              <LaunchProduct t={t} isMobileDevice={isMobile} />

              {showCheckout && (
                <Fragment>
                  {renderMainCheckout()}

                  {renderCalendar()}

                  {!sidebarScrolled && showSidebarScrolled && (
                    <div className="_d-flex _flex-column _border-top _py-4">
                      <span className="_text-1">
                        <span className="_text-gray-600">
                          <>{t('product.format')}:</>
                        </span>{' '}
                        <>{mountFormatProduct(product, t)}</>
                      </span>
                      <span className="_mt-2 _text-1">
                        <span className="_text-gray-600">
                          <>{t('product.category')}:</>
                        </span>{' '}
                        <>{t(`category.${product?.category}.name`)}</>
                      </span>
                    </div>
                  )}

                  {!sidebarScrolled && showSidebarScrolled && (
                    <div className="_d-flex _flex-column _border-top _pt-1">
                      {ownerHotmarter && (
                        <AuthorInfo
                          ownerHotmarter={ownerHotmarter}
                          showSocialMedia={false}
                          isMobile={isMobile}
                          className="_mb-4"
                        />
                      )}
                    </div>
                  )}

                  {showSidebarScrolled && clubMembershipLink && (
                    <AccessCourse
                      isMobile={isMobile}
                      salesEnabled={salesEnabled}
                      clubMembershipLink={clubMembershipLink}
                      environment={environment}
                      productId={product.productId}
                      isBlackNovember={isBlackNovember}
                    />
                  )}

                  <div className="sidebar_info _border-top">
                    <div className="_text-1 _text-gray-500 _text-center _pt-3">
                      <>{t('checkout.access_instructions')}</>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </Fragment>
      </div>
    </>
  );
};

export default withRouter(React.memo(SideCheckout));
