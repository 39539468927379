import React from 'react';
import dynamic from 'next/dynamic';

import { useRouter } from 'next/router';

import { getEnvironment } from 'utils/data';
import { sendAnalyticsEvent } from 'utils/datalayer';
import ParagraphSection from 'components/@redesign/ParagraphSection';

const ProductImage =  dynamic(() => import('components/Images/Product'));

const Description = ({ profile, isMobile }) => {
  const { product } = profile;
  const { query } = useRouter();
  const environment = getEnvironment(query?.sck);

  const readMoreEvent = () => {
    sendAnalyticsEvent({
      event: 'interaction',
      event_category: `${environment}:Product`,
      event_action: 'Click:Description:ReadMore',
      event_label: 'Read More'
    });
  };

  return (
    <div className="_d-flex _w-full redesigned-main-container">
      {!isMobile && (
        <div className="redesigned-main-title-image _mr-4">
          <ProductImage
            productFormat={product?.format}
            productName={product?.name}
            avatar={product?.avatar}
            src={product?.avatarFinal}
            data-testid="content-image"
            priority={true}
            width={176}
            height={176}
            isRedesign={false}
          />
        </div>
      )}
      <div className="_flex-1">
        <ParagraphSection
          text={profile?.product?.copy}
          readMoreClick={() => readMoreEvent()}
        />
      </div>
    </div>
  );
};

export default React.memo(Description);
